<template>
  <div>
    <h4>ASIGNAR DINERO</h4>
    <form @submit.prevent="save()">
      <div class="form-group">
        <label for>Tratamiento</label>
        <SelectTreatment
          :client_id="client_id"
          v-model="reg.treatment_plan_id"
        ></SelectTreatment>
        <app-small-form-errors
          :errors="errors.treatment_plan_id"
        ></app-small-form-errors>
      </div>
      <div class="form-group">
        <label for>Monto</label>
        <app-input-money
          :byKeyPress="true"
          :selCurrDis="true"
          :moneyCode="reg.money_code"
          :quantity.sync="reg.quantity"
        ></app-input-money>
        <app-small-form-errors
          :errors="errors.quantity"
        ></app-small-form-errors>
      </div>
      <app-button-submit text="Asignar"></app-button-submit>
    </form>
  </div>
</template>

<script>
import SelectTreatment from "./Select";
import validate from "validate.js";
import { DentalClinicService } from "../service";
import { mapState } from "vuex";

const formRules = function(current_money) {
  current_money = parseFloat(current_money);
  return {
    treatment_plan_id: {
      presence: { message: "Requerido" }
    },
    quantity: {
      presence: { message: "Requerido" },
      numericality: {
        lessThanOrEqualTo: current_money,
        notLessThanOrEqualTo: "Debe ser un monto menor a " + current_money
      }
    }
  };
};

export default {
  components: {
    SelectTreatment
  },
  props: {
    client_id: {}
  },
  data: () => ({
    reg: {},
    current_money: 0
  }),
  computed: {
    errors() {
      let err = validate(this.reg, formRules(this.current_money), {
        fullMessages: false
      });
      return err ? err : false;
    },
    ...mapState({
      client: state => state.dental.client
    })
  },
  methods: {
    save() {
      DentalClinicService.setMoneyToTreatment(
        this.client.id,
        this.reg.money_code,
        this.reg.treatment_plan_id,
        this.reg.quantity
      ).then(() => {
        this.$emit("submitted");
        this.$store.dispatch("dental/reloadClient");
      });
    },
    loadMoney(money_code) {
      let accounts = this.$store.state.denta.client.accounts;
      let account = accounts.filter(x => x.money_code == money_code);
      if (account.length > 0) {
        this.current_money = parseFloat(account[0].quantity);
      }
      this.reg.money_code = money_code;
      this.$forceUpdate();
    }
  }
};
</script>

<style>
</style>
