<template>
  <div
    class="alert"
    :class="{
      'alert-success': type_id == 1,
      'alert-warning': type_id == 2,
      'alert-danger': type_id == 3
    }"
  >
    <span
      :class="{
        'text-danger': type_id == 3
      }"
    >
      <b class="text-uppercase">{{ alert }}</b>
    </span>
  </div>
</template>
<script>
// import { StoreService } from "../../store-module/service";
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    type_id: {},
    alert: {},
    client_id: {}
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style></style>
