<template>
  <div>
    <div class="row mt-2">
      <div class="col">
        <ClientAlert
          class="mt-2"
          v-for="(l, i) in alerts"
          :key="i"
          :alert="l.alert"
          :type_id="l.type_id"
        ></ClientAlert>
      </div>
    </div>

    <!-- <h4>{{ client.name }}</h4> -->
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-3 d-print-none">
        <div class="d-flex ">
          <app-img-viewer
            alt="Client"
            class="img-profile rounded-circle mx-auto"
            style="height: 50px;"
            :src="clientImg"
          ></app-img-viewer>
        </div>
        <div class="text-center p-3">
          <span>{{ client.name }}</span>
          <br />
          <app-span-phonenumber
            :number="client.cell_phone_number"
          ></app-span-phonenumber>
          <br />
          {{ age }}
        </div>

        <ul class="fa-ul">
          <!-- <li>
            <router-link :to="'/dental-clinic/patients/' + client_id">
              <i class="fa-li	fas fa-teeth"></i> Odontograma
            </router-link>
          </li> -->
          <li>
            <router-link :to="`/dental-clinic/patients/${client_id}/proforms`">
              <i class="fa-li	fas fa-notes-medical"></i> Historial Proformas
            </router-link>
          </li>
          <li>
            <router-link
              :to="'/dental-clinic/patients/' + client_id + '/profile'"
              ><i class="fa-li fa fa-check-square"></i> Perfil de Cliente
            </router-link>
          </li>
          <li>
            <router-link
              :to="'/dental-clinic/patients/' + client_id + '/quotes'"
              ><i class="fa-li fa fa-calendar"></i> Historia de Citas
            </router-link>
          </li>

          <!-- <li>
            <router-link
              :to="
                '/dental-clinic/patients/' +
                  client_id +
                  '/treatment-plans-no-ini'+
              "
              ><i class="fa-li fas fa-file-medical"></i> Tratamientos no
              iniciados
            </router-link>
          </li> -->
          <li>
            <router-link
              :to="'/dental-clinic/patients/' + client_id + '/treatment-plans'"
            >
              <i class="fa-li fas fa-file-prescription"></i> Historias Clinicas
            </router-link>
          </li>
          <li v-show="comments.show">
            <router-link :to="`/dental-clinic/patients/${client_id}/comments`">
              <i class="fa-li far fa-comment"></i> Comentarios
            </router-link>
          </li>
          <!-- <li>
            <router-link :to="`/dental-clinic/patients/${client_id}/contact-tasks`">
              <i class="fa-li far fa-comment"></i> Tareas de Contacto
            </router-link>
          </li> -->
          <li>
            <router-link
              :to="'/dental-clinic/patients/' + client_id + '/alerts'"
              ><i class="fa-li fa fa-exclamation"></i> Alertas
            </router-link>
          </li>
          <li>
            <router-link
              :to="'/dental-clinic/patients/' + client_id + '/files'">
              <i class="fa-li fa fa-file-prescription"></i> Archivos
            </router-link>
          </li>
          <li v-show="sales.show">
            <b>
              FACTURACION
            </b>
          </li>
          <li v-show="sales.show">
            <router-link
              :to="'/dental-clinic/patients/' + client_id + '/sales-paid-out'"
              ><i class="fa-li fas fa-book"></i> Pagos Recibidos
            </router-link>
          </li>
          <li v-show="sales.show">
            <router-link
              :to="'/dental-clinic/patients/' + client_id + '/to-pay'"
              ><i class="fa-li fas fa-file-alt"></i> Recaudación
            </router-link>
          </li>
        </ul>
      </div>
      <div class="col">
        <router-view></router-view>
      </div>
    </div>

    <app-modal-basic ref="dsmtt">
      <SetMoneyToTreatment
        @submitted="$refs.dsmtt.hide()"
        ref="smtt"
        :client_id="client.id"
      ></SetMoneyToTreatment>
    </app-modal-basic>
  </div>
</template>

<script>
import SetMoneyToTreatment from "../treatments/SetMoneyToTreatment";
import { mapState } from "vuex";
import { myMoment } from "../../utils/myMoment";
import { StoreService } from "../../store-module/service";
import ClientAlert from "../../general-module/alerts/ClientAlert";

export default {
  components: {
    SetMoneyToTreatment,
    ClientAlert
  },
  props: {
    client_id: undefined
  },
  data: () => ({
    alerts: []
  }),
  computed: {
    ...mapState({
      client: s => s.dental.client,
      client_alerts: s => s.config.store.client_alerts,
      comments: s => s.config.comments,
      clientImg: s => {
        return s.dental.client.avatar_file_id
          ? `${process.env.VUE_APP_API_URL}files/${s.dental.client.avatar_file_id}`
          : "/img/default_avatar.jpg";
      },
      sales: s => s.config.store.sales
    }),
    age() {
      return myMoment(this.client.birthdate).fromNow(true);
    }
    // client() {
    //   return this.$store.state.dental.client;
    // }
  },
  methods: {
    setMoneyTotTreatment(money_code) {
      this.$refs.dsmtt.show();
      this.$refs.smtt.loadMoney(money_code);
    }
  },
  watch: {
    client_id: {
      handler(to) {
        if (to) {
          this.alerts = [];
          StoreService.getClientAlerts({ client_id: to }).then(alerts => {
            this.alerts = alerts;
          });
          this.$store.dispatch("dental/loadClientFromId", to);
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
li {
  padding-bottom: 0.5rem;
}
</style>
